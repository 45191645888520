import React from "react";
import Container from "./container";
import SearchResultList from "../components/search-result-list";

import * as styles from "../styles/components/search-result.module.css";

const SearchResult = ({ title, results, query }) => {

  return (
    <div className={styles.root}>
      <Container>
        {title && (<h1 className={styles.title}>{title}</h1>)}
        {(results && results.length > 0) ? (
          <SearchResultList results={results} query={query} />
        ) : (
          <div className={styles.content}>
            <p>Sorry, no products were found. Please try a different term or browse our products using the navigation above.</p>
          </div>
        )}
      </Container>
    </div>
  );
}

export default SearchResult;
