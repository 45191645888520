import React from "react";
import { cn } from "../lib/helpers";
import ProductCard from "./product-card";

import * as styles from "../styles/components/products-list.module.css";

const SearchResultList = ({ results, query }) => {

  return (
    <div className={styles.root}>
      <div className="grid">
        {query ? (
          <>
            {results.map(result => (
              <div key={result.id} className={cn("col-12 col-md-6 col-xl-4", styles.item)}>
                <ProductCard product={result} />
              </div>
            ))}
          </>
        ) : (
          <>
            {results.map(({node: result}) => (
              <div key={result.id} className={cn("col-12 col-md-6 col-xl-4", styles.item)}>
                <ProductCard product={result} />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default SearchResultList;
