import React from 'react';
import { graphql } from 'gatsby';
import Seo from "../components/seo";
import Layout from "../containers/layout";
import { useFlexSearch } from 'react-use-flexsearch';

import SearchResult from "../components/search-result";

const SearchPage = ({
  location,
  data: {
    localSearchPages: { index, store },
    allSanityProduct: { edges },
  },
}) => {

  const params = new URLSearchParams(location.search);
  const query = params.get("q");
  const searchedPosts = useFlexSearch(query, index, store);
  const allPosts = edges;
  const results = query ? searchedPosts : allPosts;

  const pageTitle = query ? "You searched for " + query : "You searched for all products";
  const headerTitle = query ? 'Results for “' + query + '” (' + results.length + ')' : 'Results for “all products” (' + results.length + ')';

  return (
    <Layout>
      <Seo
        title={pageTitle}
      />
      <SearchResult title={headerTitle} results={results} query={query} />
    </Layout>
  );
};

export const pageQuery = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query {
    localSearchPages {
      index
      store
    }
    allSanityProduct(
      sort: { fields: [title], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          featuredImage {
            ...SanityImage
            alt
          }
          secondaryFeaturedImage {
            ...SanityImage
            alt
          }
        }
      }
    }
  }
`

export default SearchPage;
